const mockTracker = () => {};

const tracker = (...params) => {
  console.log('tracker', params);
  const useTracker = window.snowplow || mockTracker;
  useTracker(...params);
};

const track = ({
  name,
  value,
  screen,
  pobId,
}) => {
  tracker('trackSelfDescribingEvent', {
    schema: 'iglu:fi.sok/abc-mobiili-event/jsonschema/1-0-0',
    data: {
      name,
      value: `${value}`,
      screen,
      pob_id: pobId,
      app_language: 'fi',
      app_name: process.env.SNOWPLOW_APP_NAME,
    },
  });
};


const trackError = (message, filename, error) => tracker(
  'trackError',
  message,
  filename,
  null, // lineno
  null, // colno
  error,
);

const trackPageView = (page, pobId) => tracker('trackPageView', page, [{
  schema: 'iglu:fi.sok/abc-mobiili-screen/jsonschema/1-0-0',
  data: {
    app_language: 'fi',
    app_name: process.env.SNOWPLOW_APP_NAME,
    pob_id: pobId,
  },
}]);

export default {
  track,
  trackError,
  trackPageView,
};
