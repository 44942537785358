import React from 'react';
import PropTypes from 'prop-types';

import './notification.css';

const Notification = ({ title, description, action }) => (
  <div className="fill-area notification" data-test-key="full-screen-notification">
    <span className="notification__title">{title}</span>
    {description && (
      <p className="notification__description">{description}</p>
    )}
    {action.onClick && action.text && (
      <div className="notification__action" onClick={action.onClick}>{action.text}</div>
    )}
  </div>
);

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
};

Notification.defaultProps = {
  action: {},
  description: '',
};

export default Notification;
