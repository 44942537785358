import React from 'react';
import PropTypes from 'prop-types';

const OrderSettings = ({ printBong }) => (
  <div className="fill-area order-settings">
    <span className="order-settings__title">Tulosta uusi bongilappu</span>
    <div className="buttons">
      <div
        className="button button-positive"
        onClick={() => printBong()}
      >
        Tulosta bongi
      </div>
    </div>
  </div>
);

OrderSettings.propTypes = {
  printBong: PropTypes.func.isRequired
};

export default OrderSettings;
