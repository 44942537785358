import React from 'react';
import PropTypes from 'prop-types';

const CashierReportViewHeader = ({ onBackClick }) => (
  <div className="main-header" data-test-key="cashier-report-view__header">
    <div className="button button-positive" onClick={onBackClick}>Takaisin</div>
  </div>
);

CashierReportViewHeader.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};

export default CashierReportViewHeader;
