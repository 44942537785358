import React from 'react';
import PropTypes from 'prop-types';

import { QUEUE_OPTIONS_MINUTES } from '../constants';
import { queueText } from '../util';

const QueueButton = ({ currentQueue, onQueueSelect }) => queueOption => (
  <div
    key={queueOption}
    className={queueOption === currentQueue ? 'queue-options__button-selected queue-options__button' : 'queue-options__button'}
    onClick={() => onQueueSelect(queueOption)}
  >
    {queueText(queueOption)}
  </div>
);

const QueueOptions = ({ currentQueue, onQueueSelect }) => (
  <div className="fill-area queue-options">
    <span className="queue-options__title">Jonon pituus</span>
    <div className="queue-options__buttons">
      {QUEUE_OPTIONS_MINUTES.slice(0, 3).map(QueueButton({ currentQueue, onQueueSelect }))}
    </div>
    <div className="queue-options__buttons">
      {QUEUE_OPTIONS_MINUTES.slice(3, 6).map(QueueButton({ currentQueue, onQueueSelect }))}
    </div>
  </div>
);

QueueOptions.propTypes = {
  currentQueue: PropTypes.oneOf(QUEUE_OPTIONS_MINUTES).isRequired,
  onQueueSelect: PropTypes.func.isRequired,
};

export default QueueOptions;
