import React from 'react';
import PropTypes from 'prop-types';
import './superheader.css';

const Superheader = ({
  visible, text, bgColor, textColor,
}) => (visible ? (
  <div className="superheader" style={{ backgroundColor: bgColor }}>
    <div className="superheader__text" style={{ color: textColor }}>{text}</div>
  </div>
) : null);

Superheader.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
};
Superheader.defaultProps = {
  visible: false,
  bgColor: '#FFAA00',
  textColor: '#FFFFFF',
};
export default Superheader;
