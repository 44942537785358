import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../checkbox';

const BlacklistItemRow = ({
  togglePortionAvailability, portion,
}) => (
  <div className={classNames('blacklist-category-item', { 'blacklist-category-item--available': portion.available })} onClick={(event) => { togglePortionAvailability(portion.ean, portion.available); event.preventDefault(); }}>
    <div className="blacklist-category-item__checkbox-container">
      <Checkbox id={portion.ean} checked={!!portion.available} />
    </div>
    <div className="blacklist-category-item__portion-name">{portion.name}</div>
    <div
      className={classNames('blacklist-category-item__availability', {
        'blacklist-category-item__availability--blacklisted': !portion.available,
      })}
    >
      {portion.available ? 'Myynnissä' : 'Poistettu myynnistä'}
    </div>
  </div>
);


BlacklistItemRow.propTypes = {
  portion: PropTypes.object.isRequired,
  togglePortionAvailability: PropTypes.func.isRequired,
};


export default BlacklistItemRow;
