import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BlacklistContainer from './blacklistContainer';
import { getBlacklistHeaderText } from '../../util';
import './blacklist.css';

const TAB_ALACARTE = 1;
const TAB_BURGERS = 2;
const CATEGORIES = {
  A_LA_CARTE: 'A_LA_CARTE',
  ABC_BURGER: 'ABC_BURGER',
};
const BlacklistView = ({
  toggleOptions, menu, togglePortionAvailability,
}) => {
  const [selectedTab, setSelectedTab] = useState(TAB_ALACARTE);
  const getCategories = () => menu.portionGroups.filter((pg) => {
    if (selectedTab === TAB_ALACARTE) {
      return pg.category === CATEGORIES.A_LA_CARTE;
    } if (selectedTab === TAB_BURGERS) {
      return pg.category === CATEGORIES.ABC_BURGER;
    }
    return pg;
  });
  const alaCartePortions = menu.portionGroups.filter(pg => pg.category === CATEGORIES.A_LA_CARTE).reduce((acc, pg) => acc.concat(pg.portions), []);
  const burgerPortions = menu.portionGroups.filter(pg => pg.category === CATEGORIES.ABC_BURGER).reduce((acc, pg) => acc.concat(pg.portions), []);
  const showTab = alaCartePortions.length > 0 && burgerPortions.length > 0;
  return (
    <div>
      <div data-test-key="main-header" className="portions-blacklist-main-header main-header">
        <div className="portions-blacklist-header-wrapper">
          <div className="portions-blacklist-header">
            <div className="portions-blacklist-header__button button button-positive" onClick={() => toggleOptions(null)}>Takaisin</div>
            <h2 className="portions-blacklist-header__text">
              {getBlacklistHeaderText(menu.isLoading ? undefined : menu)}
            </h2>
          </div>
          {showTab && (
            <div
              className="blacklist-category-tab-container"
            >
              <div
                className={classNames('blacklist-category-tab-container__tab', {
                  'blacklist-category-tab-container__tab--selected': selectedTab === TAB_ALACARTE,
                })}
                onClick={() => setSelectedTab(TAB_ALACARTE)}
              >
                {`A La Carte (${alaCartePortions.filter(p => p.available).length}/${alaCartePortions.length})`}
              </div>
              <div
                className={classNames('blacklist-category-tab-container__tab', {
                  'blacklist-category-tab-container__tab--selected': selectedTab === TAB_BURGERS,
                })}
                onClick={() => setSelectedTab(TAB_BURGERS)}
              >
                {`ABC Burger (${burgerPortions.filter(p => p.available).length}/${burgerPortions.length})`}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="portions-blacklist-menu-container">
        {menu && (
          <BlacklistContainer selectedTab={selectedTab} togglePortionAvailability={togglePortionAvailability} categories={getCategories()} />
        )}
      </div>
    </div>
  );
};
BlacklistView.propTypes = {
  toggleOptions: PropTypes.func.isRequired,
  menu: PropTypes.object,
  togglePortionAvailability: PropTypes.func.isRequired,
};
BlacklistView.defaultProps = {
  menu: {
    isLoading: true,
    portionGroups: [],
  },
};
export default BlacklistView;
