const sounds = {
  newOrder: {
    type: 'square',
    frequency: 1000,
    length: 400,
  },
  tableNumberGiven: {
    type: 'square',
    frequency: 800,
    length: 400,
  },
  orderUrgency2: {
    type: 'sawtooth',
    frequency: 1500,
    length: 600,
  },
  orderUrgency3: {
    type: 'sawtooth',
    frequency: 2000,
    length: 800,
  },
  marketOrderUrgency3: {
    type: 'square',
    frequency: 1000,
    length: 1000,
  },
};

const audioContext = window.AudioContext ? new window.AudioContext() : null;
const masterGainNode = audioContext && audioContext.createGain();
if (masterGainNode) {
  masterGainNode.connect(audioContext.destination);
  masterGainNode.gain.value = 1.0;
}

const playSound = (sound) => {
  if (!audioContext) {
    console.log('No audioContext');
    return;
  }
  if (!sounds[sound]) {
    console.log(`Undefined sound ${sound}`);
    return;
  }
  const { type, frequency, length } = sounds[sound];
  let osc = audioContext.createOscillator();
  osc.connect(masterGainNode);
  osc.type = type;
  osc.frequency.value = frequency;
  osc.start();
  setTimeout(() => {
    osc.stop();
    osc = null;
  }, length);
};

export default {
  playSound,
};
