import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UrgencyWrapper from './urgencyWrapper';
import './fullScreenNotification.css';

const FullScreenNotificationComponent = ({
  title, urgency, smallerFonts, closeButtonText, descriptions,
}) => (
  <UrgencyWrapper urgency={urgency}>

    {descriptions.map((desc, index) => (
      <div
        key={index}
        className={classNames('full-screen-notification__description', {
          'full-screen-notification__text--font-small': smallerFonts,
        })}
      >
        {desc}
      </div>
    ))}
    <div className={classNames('full-screen-notification__title', {
      'full-screen-notification__text--font-medium': smallerFonts,
    })}
    >
      {title}
    </div>
    <div className={classNames('full-screen-notification__close-button', {
      'full-screen-notification__close-button_non-urgent': urgency === undefined,
    })}
    >
      {closeButtonText}
    </div>
  </UrgencyWrapper>
);

FullScreenNotificationComponent.propTypes = {
  title: PropTypes.string,
  urgency: PropTypes.number,
  smallerFonts: PropTypes.bool,
  closeButtonText: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
};
FullScreenNotificationComponent.defaultProps = {
  title: undefined,
  urgency: undefined,
  smallerFonts: false,
  closeButtonText: undefined,
  descriptions: undefined,
};

export default FullScreenNotificationComponent;
