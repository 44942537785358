import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CashierReportViewHeader from './header';
import CashierReportList from './list';
import CashierReport from './report';

const CashierReportView = ({ onCashierReportViewExit }) => {
  const [selectedReport, setSelectedReport] = useState();
  const resetSelectedReport = () => setSelectedReport(null);

  const handleCashierEntryUpdate = updatedEntry => (
    updatedEntry.completed
      ? resetSelectedReport()
      : setSelectedReport(updatedEntry)
  );

  return (
    <div className="flexbox-parent" data-test-key="cashier-report-view">
      {selectedReport ? (
        <CashierReport
          currentReport={selectedReport}
          onCashierEntryUpdate={handleCashierEntryUpdate}
          onCashierReportClose={resetSelectedReport}
          handleReportFetchErrorClose={resetSelectedReport}
        />
      ) : (
        <React.Fragment>
          <CashierReportViewHeader onBackClick={onCashierReportViewExit} />
          <CashierReportList onReportSelect={setSelectedReport} />
        </React.Fragment>
      )}
    </div>
  );
};

CashierReportView.propTypes = {
  onCashierReportViewExit: PropTypes.func.isRequired,
};

export default React.memo(CashierReportView);
