import React from 'react';
import PropTypes from 'prop-types';

import Time from './time';

import {
  groupOrderItems,
} from '../util';

const BuffetRow = ({ order }) => (
  <div>
    <div
      data-test-key={`order-list__order-item-${order.displayName}`}
      className="order-list__order"
    >
      <div className="order-list-order__column order-list-order__column_item-count">
        <div className="order-list-order__item-count">{order.items.length}</div>
      </div>
      <div className="order-list-order__column_middle">
        <div className="order-list-order__column order-list-order__column_order-content order-details-order-item__name">{groupOrderItems(order.items).map((itemGroup, index) => <div key={`order-list-order-${order.displayName}-group-${index}`}>{`${itemGroup.itemCount} x ${itemGroup.orderItem.name}`}</div>)}</div>
      </div>
      <div className="order-list-order__column_middle">
        <div className="order-list-order__column order-list-order__column_order-id order-id-badge">{order.displayName}</div>
      </div>
      <div className="order-list-order__column order-list-order__delivery-time order-list-order__delivery-time_time">
        <Time time={order.dateAuthorized} />
      </div>

    </div>
  </div>
);

BuffetRow.propTypes = {
  order: PropTypes.object.isRequired,
};

const Buffets = ({ orders }) => (
  <div className="fill-area queue-options purchased-buffets">
    <div className="queue-options__title purchased-buffets__container">
      <span className="queue-options__title-left purchased-buffets__container-title">
      Tänään ostetut noutopöydät (
        {orders ? `${orders.reduce((acc, order) => acc + order.items.length, 0)}` : '0'}
      kpl)
        {' '}

      </span>
      {orders.map(order => <BuffetRow key={order.orderId} order={order} />)}
    </div>
  </div>
);

Buffets.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default Buffets;
