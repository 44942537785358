import React from 'react';
import PropTypes from 'prop-types';

import './fullScreenError.css';

const FullScreenError = ({
  title,
  description,
  closeButtonText,
}) => (
  <div
    data-test-key="full-screen-error"
    className="full-screen-error"
  >
    <div className="full-screen-error__title">{title}</div>
    <div className="full-screen-error__description">{description}</div>
    <div className="button button-positive">
      {closeButtonText}
    </div>
  </div>
);

FullScreenError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  closeButtonText: PropTypes.string.isRequired,
};

FullScreenError.defaultProps = {
  description: '',
};

export default FullScreenError;
