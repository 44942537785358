import React from 'react';
import PropTypes from 'prop-types';
import { getBlacklistHeaderText } from '../../util';
import Superheader from '../superheader';

const BlacklistBanner = ({ visible, menu }) => <Superheader text={getBlacklistHeaderText(menu)} visible={visible} />;

BlacklistBanner.propTypes = {
  visible: PropTypes.bool,
  menu: PropTypes.object,
};
BlacklistBanner.defaultProps = {
  visible: false,
  menu: undefined,
};
export default BlacklistBanner;
