import React from 'react';
import PropTypes from 'prop-types';

import './checkbox.css';

const Checkbox = ({ checked, id, onChange }) => (
  <div className="checkbox-container">
    <label htmlFor={`checkbox-${id}`} id={`checkbox-${id}-label`}>
      <input
        id={`checkbox-${id}`}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={id}
        data-test-key={`checkbox-${id}`}
      />
      {checked ? (
        <div className="checkbox checkbox_checked">
          <div className="checkbox__checkmark" />
        </div>
      ) : (
        <div className="checkbox" />
      )}
    </label>
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: () => {},
};

export default Checkbox;
