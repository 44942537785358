import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import './header.css';
import { queueText, I18n } from '../util';
import {
  VIEWS,
  VIEW_OPTIONS_VIEWS,
  VIEW_OPTIONS_QUEUE,
  VIEW_KITCHEN,
  VIEW_KITCHEN_AND_FRONT_OF_HOUSE,
  VIEW_FRONT_OF_HOUSE,
  VIEW_BUFFETS,
  VIEW_MARKET_ORDERS,
} from '../constants';

const Header = ({
  time,
  toggleOptions,
  showOptions,
  currentView,
  currentQueue,
  onLockClick,
  buffetOrders,
  marketOrders,
}) => (
  showOptions ? (
    <div data-test-key="main-header" className={`main-header main-header-${showOptions}`}>
      <span className="version">
        {process.env.SNOWPLOW_APP_ID}
        @
        {process.env.REV}
      </span>
      <div className="button button-positive" onClick={() => toggleOptions(null)}>Sulje</div>
    </div>
  ) : (
    <div data-test-key="main-header" className="main-header main-header-orders">
      <div className="main-header__view">
        <div data-test-key="main-header__view" className="button button-positive" onClick={() => toggleOptions(VIEW_OPTIONS_VIEWS)}>{VIEWS[currentView].title}</div>
        {currentQueue && (currentView === VIEW_FRONT_OF_HOUSE || currentView === VIEW_KITCHEN_AND_FRONT_OF_HOUSE) ? <div data-test-key="main-header__view" className="button button-positive" onClick={() => toggleOptions(VIEW_BUFFETS)}>{`${buffetOrders ? buffetOrders.reduce((acc, order) => acc + order.items.length, 0) : 0} x Noutopöytä`}</div> : ''}
        {marketOrders && currentQueue && (currentView === VIEW_FRONT_OF_HOUSE || currentView === VIEW_KITCHEN_AND_FRONT_OF_HOUSE) ? <div data-test-key="main-header__view" className="button button-positive" onClick={() => toggleOptions(VIEW_MARKET_ORDERS)}>{`${marketOrders ? marketOrders.length : 0} x Keräily`}</div> : ''}
      </div>
      <div className="main-header__time">
        <div data-test-key="main-header__time">
          {I18n.time.format(time)}
        </div>
      </div>
      <div className="main-header__right">
        {currentQueue && [VIEW_KITCHEN, VIEW_KITCHEN_AND_FRONT_OF_HOUSE].includes(currentView) ? (
          <div data-test-key="main-header__queue" className="button button-queue" onClick={() => toggleOptions(VIEW_OPTIONS_QUEUE)}>{queueText(currentQueue)}</div>
        ) : (
          <div data-test-key="main-header__queue_placeholder" className="button button-queue button-placeholder">
            {currentQueue && currentView === VIEW_FRONT_OF_HOUSE ? queueText(currentQueue) : ''}
          </div>
        )}
        {currentView === VIEW_FRONT_OF_HOUSE && (
          <div
            data-test-key="main-header__lock"
            className="button button-warn"
            onClick={onLockClick}
          >
            Lukitse
          </div>
        )}
      </div>
    </div>
  )
);

Header.propTypes = {
  time: PropTypes.instanceOf(moment).isRequired,
  toggleOptions: PropTypes.func.isRequired,
  showOptions: PropTypes.string,
  currentView: PropTypes.string.isRequired,
  currentQueue: PropTypes.number,
  onLockClick: PropTypes.func.isRequired,
  buffetOrders: PropTypes.array,
  marketOrders: PropTypes.array,
};

Header.defaultProps = {
  showOptions: null,
  currentQueue: null,
  buffetOrders: null,
  marketOrders: null,
};

export default Header;
