import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import OrderList from './list';
import OrderDetails from './details';

const OrdersView = ({
  ordersWithUrgencyByStatuses,
  selectedOrder,
  selectOrder,
  onOrderListScroll,
  moveToStatus,
  time,
  pendingActions,
  toggleOptions,
}) => (
  <div className="fill-area flexbox-item-grow">
    <div className="side-bar fill-area-content flexbox-item-grow">
      {ordersWithUrgencyByStatuses && (
        <OrderList
          ordersByStatuses={ordersWithUrgencyByStatuses}
          onOrderSelect={selectOrder}
          selectedOrder={selectedOrder}
          onOrderListScroll={onOrderListScroll}
        />
      )}
    </div>

    <div className="content fill-area-content flexbox-item-grow">
      {selectedOrder && (
        <OrderDetails
          key={`order-${selectedOrder.orderId}`}
          order={selectedOrder}
          moveToStatus={moveToStatus}
          time={time}
          pendingActions={pendingActions}
          toggleOptions={toggleOptions}
        />
      )}
    </div>
  </div>
);

OrdersView.propTypes = {
  ordersWithUrgencyByStatuses: PropTypes.array.isRequired,
  selectedOrder: PropTypes.shape({}),
  selectOrder: PropTypes.func.isRequired,
  onOrderListScroll: PropTypes.func,
  moveToStatus: PropTypes.func.isRequired,
  time: PropTypes.instanceOf(moment).isRequired,
  pendingActions: PropTypes.array.isRequired,
  toggleOptions: PropTypes.func.isRequired,
};

OrdersView.defaultProps = {
  onOrderListScroll: () => {},
  selectedOrder: undefined,
};

export default OrdersView;
