import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { URGENCY_LEVEL_HIGH, URGENCY_LEVEL_LOW, URGENCY_LEVEL_NONE } from '../constants';

const MarketOrderRow = ({ order }) => {
  const { firstName, lastName } = order.customer;
  return (
    <div>
      <div
        data-test-key={`order-list__order-item-${order.orderNumber}`}
        className={classNames('order-list__order', {
          'order-list__order_urgency-0': order.urgencyLevel === undefined,
          'order-list__order_urgency-1': order.urgencyLevel === URGENCY_LEVEL_NONE,
          'order-list__order_urgency-2': order.urgencyLevel === URGENCY_LEVEL_LOW,
          'order-list__order_urgency-3': order.urgencyLevel === URGENCY_LEVEL_HIGH,
        })}
      >
        <div className="order-list-order__column_middle order-list-customer">
          <div className="order-list-customer__name">
            {`${lastName} ${firstName}`}
          </div>

        </div>
        <div className="order-list-order__column_middle">
          <div className="order-list-order__column order-list-order__column_order-id order-id-badge reset-width">{order.orderNumber}</div>
        </div>
        <div className="order-list-order__column order-list-order__delivery-time order-list-order__delivery-time_time reset-width">
          {order.deliveryTime}
        </div>

      </div>
    </div>
  );
};

MarketOrderRow.propTypes = {
  order: PropTypes.object.isRequired,
};

const MarketOrders = ({ orders }) => (
  <div className="fill-area queue-options market-orders">
    <div className="queue-options__title market-orders__container">
      <span className="queue-options__title-left market-orders__container-title">
        Keräiltävät verkkokauppatilaukset
      </span>
      {orders.map(order => <MarketOrderRow key={order.orderNumber} order={order} />)}
    </div>
  </div>
);
MarketOrders.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default MarketOrders;
