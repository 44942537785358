import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './fullScreenNotification.css';
import { URGENCY_LEVEL_HIGH, URGENCY_LEVEL_LOW, URGENCY_LEVEL_NONE } from '../constants';

const UrgencyWrapper = ({ urgency, children }) => (
  <div
    data-test-key="full-screen-notification_urgency"
    className={classNames('full-screen-notification', {
      'full-screen-notification_urgency-1': urgency === URGENCY_LEVEL_NONE,
      'full-screen-notification_urgency-2': urgency === URGENCY_LEVEL_LOW,
      'full-screen-notification_urgency-3': urgency === URGENCY_LEVEL_HIGH,
    })}
  >
    {children}
  </div>
);
UrgencyWrapper.propTypes = {
  children: PropTypes.node,
  urgency: PropTypes.number,
};
UrgencyWrapper.defaultProps = {
  urgency: undefined,
  children: undefined,
};


export default UrgencyWrapper;
