import React from 'react';
import PropTypes from 'prop-types';
import appLock from '../app-lock';

import './change-pin-view.css';

import PinInput from './pin-input';

const ChangePinView = ({ onPinChanged, onBackClick }) => {
  const [currentPinOk, setCurrentPinOk] = React.useState();
  const [currentPinError, setCurrentPinError] = React.useState();
  const handleCurrentPinGiven = (pin) => {
    if (appLock.pinValidate(pin)) {
      // Adding timeout so that user sees that the last number is filled in (ux)
      setTimeout(() => {
        setCurrentPinOk(true);
      }, 300);
    } else {
      setCurrentPinError('Väärä tunnusluku, yritä uudelleen');
      setTimeout(() => {
        setCurrentPinError(undefined);
      }, 2000);
    }
  };

  const [newPin, setNewPin] = React.useState();
  const handleNewPinGiven = (pin) => {
    setTimeout(() => {
      setNewPin(pin);
    }, 300);
  };

  const [newPinOk, setNewPinOk] = React.useState();
  const [newPinAgain, setNewPinAgain] = React.useState();
  const [newPinAgainError, setNewPinAgainError] = React.useState();
  const handleNewPinAgainGiven = (pin) => {
    setTimeout(() => {
      let errorOccurred;
      if (pin === newPin) {
        if (appLock.setPin(pin) === true) {
          setNewPinOk(true);
        } else {
          setNewPinAgainError('Tunnusluvun vaihtaminen epäonnistui');
          errorOccurred = true;
        }
      } else {
        setNewPinAgainError('Tunnusluvut eivät täsmänneet. Yritä uudelleen.');
        errorOccurred = true;
      }
      if (errorOccurred) {
        setTimeout(() => {
          setNewPin(undefined);
          setNewPinAgain(undefined);
          setNewPinAgainError(undefined);
        }, 2000);
      }
    }, 300);
  };

  return (
    <div className="flexbox-parent" data-test-key="change-pin">
      {!newPinOk && (
      <div className="main-header" data-test-key="change-pin__header">
        <div className="button button-positive" onClick={onBackClick}>Takaisin</div>
      </div>
      )}
      <div className="change-pin">
        {!currentPinOk && (
          <div>
            <span className="change-pin__title">{currentPinError || 'Anna nykyinen tunnuslukusi'}</span>
            {/* Slight hack to force re-render of pin input when error is cleared */}
            {currentPinError === undefined && (
              <PinInput
                onPinGiven={handleCurrentPinGiven}
                error={false}
              />
            )}
            {currentPinError !== undefined && (
              <PinInput
                onPinGiven={handleCurrentPinGiven}
                error={Boolean(currentPinError)}
              />
            )}
            {/* Hack ends */}
          </div>
        )}
        {currentPinOk && !newPin && (
          <div>
            <span className="change-pin__title">Anna uusi tunnusluku</span>
            <PinInput
              onPinGiven={handleNewPinGiven}
              error={Boolean(newPinAgainError)}
            />
          </div>
        )}

        {currentPinOk && newPin && !newPinAgain && !newPinOk && (
          <div>
            <span className="change-pin__title">{newPinAgainError || 'Anna valitsemasi tunnusluku uudelleen'}</span>
            <PinInput
              onPinGiven={handleNewPinAgainGiven}
              error={Boolean(newPinAgainError)}
            />
          </div>
        )}

        {newPinOk && (
          <div className="change-pin__finish">
            <span className="change-pin__title">Tunnusluku asetettu</span>
            <div onClick={onPinChanged} className="change-pin__ok-button">Ok</div>
          </div>
        )}

      </div>
    </div>
  );
};

ChangePinView.propTypes = {
  onPinChanged: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default ChangePinView;
