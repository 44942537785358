import PropTypes from 'prop-types';

import { PRICE_TYPES } from '../../constants';

export const ReportShape = {
  date: PropTypes.string.isRequired,
  status: PropTypes.shape({
    EMPLOYEE_DISCOUNT: PropTypes.oneOf(['DONE', 'NOT_DONE']).isRequired,
    REGULAR_PRICE: PropTypes.oneOf(['DONE', 'NOT_DONE']).isRequired,
    CAMPAIGN_DISCOUNT: PropTypes.oneOf(['DONE', 'NOT_DONE']).isRequired,
  }),
};

export const PriceTypeDataShape = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      count: PropTypes.number.isRequired,
      ean: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sum: PropTypes.number.isRequired,
    })),
  })),
  priceType: PropTypes.oneOf(PRICE_TYPES),
  sum: PropTypes.number.isRequired,
};

export const ReportDetailsShape = {
  date: PropTypes.string.isRequired,
  pobId: PropTypes.string.isRequired,
  priceTypes: PropTypes.arrayOf(PropTypes.shape(PriceTypeDataShape)),
};

export default {
  ReportShape,
  PriceTypeDataShape,
  ReportDetailsShape,
};
