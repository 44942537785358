import moment from 'moment-timezone';
import { getToken, getCachedUser } from './auth/adal-config';
import analytics from './analytics';

const SOCKET_OPEN = 1;
const CONNECTION_RETRY_INTERVAL_MS = 2000;
const PULSE_INTERVAL_MS = 15000;
const PULSE_THRESHOLD_MS = 20000;

const DEBUG = false;

const connect = (handlers = {}) => {
  let connection;
  let lastPulse;
  let pulseInterval;
  let pingAttrs;

  const openConnection = () => {
    if (connection && connection.readyState < 2) {
      // Already has connection or is reconnecting
      return;
    }

    const user = getCachedUser();
    console.log('Connecting as ', user);

    getToken().then((token) => {
      connection = new WebSocket(`${process.env.WEBSOCKET_API_BASE}/food/?access_token=${token}`);
      connection.onopen = (event) => {
        if (DEBUG) console.log('Socket opened ', event);
        lastPulse = moment();
        if (typeof handlers.onopen === 'function') {
          handlers.onopen(event);
        }
      };

      connection.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (DEBUG) console.log('Connection onmessage', data);
        if (data.action === 'pong') {
          lastPulse = moment();
        }

        if (typeof handlers.onmessage === 'function') {
          handlers.onmessage(event);
        }
      };

      pulseInterval = setInterval(() => {
        if (lastPulse) {
          const lastPulseSince = moment.duration(moment().diff(lastPulse)).as('milliseconds');
          if (connection.readyState === SOCKET_OPEN && lastPulseSince >= PULSE_THRESHOLD_MS) {
            if (DEBUG) console.warn(`Last pulse over ${PULSE_THRESHOLD_MS / 1000} seconds ago, closing socket`);
            connection.close();
          }
        }
        if (connection.readyState === SOCKET_OPEN) {
          connection.send(JSON.stringify({
            action: 'ping',
            ...pingAttrs,
          }));
        }
      }, PULSE_INTERVAL_MS);

      connection.onclose = (event) => {
        const errorMsg = `Socket (readyState: ${connection.readyState}) is closed (Code: ${event.code} Reason: ${event.reason})`;
        if (DEBUG) console.error(errorMsg);

        clearInterval(pulseInterval);
        if (typeof handlers.onerror === 'function') {
          handlers.onerror(new Error(errorMsg));
        }
        setTimeout(() => {
          openConnection();
        }, CONNECTION_RETRY_INTERVAL_MS);
      };

      connection.onerror = (event) => {
        const errorMsg = `Socket (readyState: ${connection.readyState}) error (Code: ${event.code} Reason: ${event.reason})`;
        analytics.trackError(errorMsg, 'socket.js', event);
        if (DEBUG) console.error(errorMsg);
        if (typeof handlers.onerror === 'function') {
          handlers.onerror(new Error(errorMsg));
        }
        setTimeout(() => {
          openConnection();
        }, CONNECTION_RETRY_INTERVAL_MS);
      };
    }).catch((error) => {
      analytics.trackError('ADAL getToken failed', 'socket.js', error);
      console.error('ADAL getToken() failed: ', error);
      if (typeof handlers.onerror === 'function') {
        handlers.onerror(new Error(error.message));
      }
      if (error.message.match(/AADSTS50058/)) {
        // Force reload page
        window.location.reload();
      } else {
        // Keep retry loop alive
        setTimeout(() => {
          openConnection();
        }, CONNECTION_RETRY_INTERVAL_MS);
      }
    });
  };

  openConnection();

  return () => ({
    webSocket: connection,
    setPingAttrs: (attrs) => { pingAttrs = attrs; },
  });
};

export default {
  connect,
};
