import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './pin-input.css';

const PIN_LENGTH = 4;

const keyboard = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [0, '<'],
];

const Circle = ({ filled, error, i }) => (
  <div
    data-test-key={`pin-input-index-${i}`}
    className={classNames('pin-input__circle', {
      'pin-input__circle_filled': filled,
      'pin-input__circle_error': error,
    })}
  />
);

Circle.propTypes = {
  filled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
};

const Key = ({ onPress, value, error }) => {
  const handleButtonClick = () => (error ? null : onPress(value));
  return (
    <div
      className={classNames('number-input__button', { 'number-input__button-disabled': error })}
      onClick={handleButtonClick}
      data-test-key={`keyboard-button-${value}`}
    >
      {value === '<' && (
        <div className="icon-backspace" />
      )}
      {typeof value === 'number' && value}
    </div>
  );
};


Key.propTypes = {
  onPress: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  error: PropTypes.bool.isRequired,
};

const KeyboardRow = ({ row, onPress, error }) => (
  <div className="number-input__row">
    {row.map(value => (
      <Key
        key={value}
        onPress={onPress}
        value={value}
        error={error}
      />
    ))}
  </div>
);

KeyboardRow.propTypes = {
  row: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  onPress: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

const Keyboard = ({
  onPinGiven,
  error,
}) => {
  const [pin, setPin] = React.useState('');
  const handleButtonPress = (buttonValue) => {
    let newPin;
    if (buttonValue === '<') {
      newPin = pin.substr(0, pin.length - 1);
    } else {
      newPin = pin.length === PIN_LENGTH ? `${buttonValue}` : `${pin}${buttonValue}`;
    }
    setPin(newPin);
    if (newPin.length === PIN_LENGTH) {
      onPinGiven(newPin);
    }
  };

  return (
    <div className="pin-input">
      <div className="pin-input__circles">
        {[...pin, ...Array(PIN_LENGTH - pin.length)].map((char, i) => (
          <Circle key={`pin-${i}`} filled={Boolean(char)} error={error} i={i} />
        ))}
      </div>
      <div className="number-input">
        {keyboard.map((row, i) => (
          <KeyboardRow
            key={`row-${i}`}
            row={row}
            onPress={handleButtonPress}
            error={error}
          />
        ))}
      </div>
    </div>
  );
};

Keyboard.propTypes = {
  onPinGiven: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default Keyboard;
