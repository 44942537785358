/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import moment from 'moment-timezone';
import {
  ORDER_CUSTOMIZATIONS,
} from './constants';

moment.tz.setDefault('Europe/Helsinki');

const getPrintMode = useCutter => (useCutter
  ? '^MMC,N' // Cutter
  : '^MMT,N'); // Tear-off

const formatLocalDate = (dateTime) => {
  const time = moment(dateTime);
  return time.format('DD.MM.YYYY');
};

const formatLocalDeliveryTime = (dateTime) => {
  const time = moment(dateTime);
  return time.format('HH:mm');
};

const fieldLocation = (xCoord, yCoord) => `^FO${xCoord},${yCoord}\r\n`;

const fieldText = text => `^FD${text}^FS\r\n`;

const font = fontSize => `^A0N,${fontSize},${fontSize}\r\n`;

const rowWithText = (
  yCoord,
  label,
  labelFontSize = 35,
) => fieldLocation(20, yCoord) + font(labelFontSize) + fieldText(label);

const cleanupString = str => (str ? str.replace(/[^a-zA-Z0-9öåäöÅÄÖ., ":]+/g, '') : '');

/* Recommended command for text block is ^TB, but couldn't get it to work with our printer.
   Might have been some minor bug in code or firmware issue, but this ^FB seems to be fine also */
const textBlock = (
  width,
  lines,
) => `^FB${width},${lines},0,L,15\r\n`;

const multiLineText = (
  yCoord,
  width,
  lines,
  text,
  labelFontSize = 35,
) => fieldLocation(20, yCoord) + font(labelFontSize) + textBlock(width - 30, lines) + fieldText(cleanupString(text));

const rowWithIndentText = (
  yCoord,
  label,
  labelFontSize = 35,
  indent = 50,
) => fieldLocation(indent, yCoord) + font(labelFontSize) + fieldText(label);

const dividerLine = (yCoord, width) => `^FO20,${yCoord}\r\n^GB${width},5,5,B,0^FS\r\n`;

const rowWithLabelAndValue = (
  yCoord,
  label,
  value,
  labelFontSize = 35,
  valueFontSize = 35,
) => fieldLocation(20, yCoord) + font(labelFontSize) + fieldText(label)
  + fieldLocation(150, yCoord) + font(valueFontSize) + fieldText(value);

const getHeader = (order, topMargin, paperWidthDots, useCutter) => {
  let header = '';

  header += `^XA${getPrintMode(useCutter)}`;
  header += `^PON^PW${paperWidthDots}^MNN`;
  header += '^LL{{TOTALHEIGHT}}'; // Total height added later
  header += `^LH0,${topMargin}\r\n`;
  header += '^CI28\r\n';

  let currentY = 0;
  header += (rowWithLabelAndValue(currentY, 'Pvm', formatLocalDate(order.delivery.time)));
  currentY += 50;
  header += rowWithLabelAndValue(currentY, 'Tilaus', order.displayName);
  currentY += 50;
  const timeVal = formatLocalDeliveryTime(order.delivery.time) + (order.delivery.deliveryWhenReady ? ' (HETI)' : '');
  header += rowWithLabelAndValue(currentY, 'Aika', timeVal, 35, 60);
  currentY += 70;
  if (order.delivery.takeaway) {
    header += rowWithIndentText(currentY, 'MUKAAN', 45, 150);
    currentY += 70;
  }
  header += rowWithText(currentY, 'Tuotteet');
  currentY += 30;
  header += dividerLine(currentY, paperWidthDots - 25);
  currentY += 30;

  return {
    zpl: header,
    height: currentY + topMargin,
  };
};

const getBody = (order, startYcoord, width) => {
  if (!order || !order.items) {
    console.warn('Empty order or order items');
    return '';
  }

  let body = `^LH0,${startYcoord}\r\n`;

  const heightOfOneLine = 45;
  const heightOfProductMargin = 10;
  let totalBodyHeight = 0;
  order.items.forEach((item) => {
    body += rowWithText(totalBodyHeight, item.receiptName || item.name);
    totalBodyHeight += heightOfOneLine;

    if (item.additionalProducts) {
      item.additionalProducts.forEach((additional) => {
        body += rowWithIndentText(totalBodyHeight, additional.receiptName || additional.name || additional.ean);
        totalBodyHeight += heightOfOneLine;
      });
    }

    if (item.customizationChoices) {
      item.customizationChoices.forEach((customization) => {
        body += rowWithIndentText(
          totalBodyHeight,
          customization.receiptName || customization.name || customization.choice || customization.id,
        );
        totalBodyHeight += heightOfOneLine;
      });
    }
    totalBodyHeight += heightOfProductMargin;
  });

  if (order.notes) {
    if (order.notes.customizations) {
      Object.entries(order.notes.customizations).forEach(([key, value]) => {
        if (value === true) {
          body += rowWithText(totalBodyHeight, `Mukaan ${ORDER_CUSTOMIZATIONS[key]}`);
          totalBodyHeight += heightOfOneLine;
        }
      });
    }
    if (order.notes.orderCustomizations) {
      Object.entries(order.notes.orderCustomizations).forEach(([, customization]) => {
        if (customization.enabled === true) {
          body += rowWithText(
            totalBodyHeight,
            customization.receiptName || customization.name,
          );
          totalBodyHeight += heightOfOneLine;
        }
      });
    }
    if (order.notes.message) {
      const maxLines = 5;
      const msg = `Viesti: "${order.notes.message}"`;
      body += multiLineText(totalBodyHeight, width, maxLines, msg, 30);
      totalBodyHeight += maxLines * 40;
    }
  }

  return {
    zpl: body,
    height: totalBodyHeight,
  };
};

const getFooter = (startYcoord, bottomMargin, paperWidthDots) => {
  const divider = dividerLine(1, paperWidthDots - 25);
  const footer = `^LH0,${startYcoord}\r\n${divider}^XZ`;

  return {
    zpl: footer,
    height: 20 + bottomMargin,
  };
};

/*
 * JS adaptation from the MoRa native application zpl implementation
 * Spec: https://www.zebra.com/content/dam/zebra/manuals/printers/common/zpl-zbi2-pm-en.pdf
 */
const createOrderReceiptZpl = (order, paperWidthDots, cutter) => {
  const tmpHeader = getHeader(order, 150, paperWidthDots, cutter);
  const body = getBody(order, tmpHeader.height, paperWidthDots);
  const footer = getFooter(tmpHeader.height + body.height, 150, paperWidthDots);

  const labelLength = tmpHeader.height + body.height + footer.height;
  const header = tmpHeader.zpl.replace(/{{TOTALHEIGHT}}/g, Math.max(labelLength, 800));

  return `${header}${body.zpl}${footer.zpl}`;
};

export default {
  createOrderReceiptZpl,
};
