import React from 'react';
import PropTypes from 'prop-types';
import appLock from '../app-lock';

import PinInput from './pin-input';

import './lockedView.css';

const LockedView = ({ onSuccessfulPinEntry }) => {
  const [error, setError] = React.useState();
  const handlePinGiven = (pin) => {
    setTimeout(() => {
      if (appLock.unlock(pin)) {
        onSuccessfulPinEntry();
      } else {
        setError('Väärä tunnusluku');
        setTimeout(() => {
          setError(undefined);
        }, 2000);
      }
    }, 300);
  };
  return (
    <div className="locked-view">
      <span className="locked-view__title">{error || 'Anna tunnusluku'}</span>
      {/* Slight hack to force re-render of pin input when error is cleared */}
      {error === undefined && (
      <PinInput
        onPinGiven={handlePinGiven}
        error={false}
      />
      )}
      {error !== undefined && (
      <PinInput
        onPinGiven={handlePinGiven}
        error={Boolean(error)}
      />
      )}
      {/* Hack ends */}
    </div>
  );
};

LockedView.propTypes = {
  onSuccessfulPinEntry: PropTypes.func.isRequired,
};

export default LockedView;
