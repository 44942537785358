
const STORAGE_VARIABLE_PIN = 'app-lock-pin';
const STORAGE_VARIABLE_LOCKED = 'app-lock-locked';

const isLocked = () => window.localStorage.getItem(STORAGE_VARIABLE_LOCKED) === 'true';
const lock = () => {
  try {
    window.localStorage.setItem(STORAGE_VARIABLE_LOCKED, true);
    return true;
  } catch (e) {
    console.error('Locking app failed');
    return e;
  }
};
const getPin = () => window.localStorage.getItem(STORAGE_VARIABLE_PIN);
const hasPin = () => Boolean(getPin());
const setPin = (pin) => {
  try {
    window.localStorage.setItem(STORAGE_VARIABLE_PIN, pin);
    return true;
  } catch (e) {
    console.error('Updating pin failed');
    return e;
  }
};
const pinValidate = pin => pin === getPin();

const unlock = (pin) => {
  if (pinValidate(pin)) {
    window.localStorage.setItem(STORAGE_VARIABLE_LOCKED, false);
    return true;
  }
  return false;
};

export default {
  isLocked,
  lock,
  unlock,
  hasPin,
  setPin,
  pinValidate,
};
