
const BLACKLIST_BASE_URL = `${process.env.REST_API_BASE}/food/v1/menu/blacklist`;
export const fetchPortionBlacklistForPobViaRest = token => fetch(BLACKLIST_BASE_URL,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
export const toggleBlacklistForEanViaRest = (token, ean, available) => fetch(BLACKLIST_BASE_URL, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify({ ean, available }),
});

export default {
  fetchPortionBlacklistForPobViaRest,
  toggleBlacklistForEanViaRest,
};
