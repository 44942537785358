import React from 'react';
import PropTypes from 'prop-types';

import './list.css';
import classNames from 'classnames';

import Time from '../time';
import {
  resolveOrderItems,
  timeInMinutesOrTime,
} from '../../util';

import {
  ORDER_STATUSES,
  ORDER_ITEM_TYPES,
  ORDER_STATUS_PRODUCTION_FINISHED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_EXPIRED,
  ORDER_STATUS_DISPOSED,
  TIME_NOW,
} from '../../constants';

const takeawayResolver = order => Boolean(order.delivery.takeaway);
const orderItemTypeResolver = (order, itemType) => order.items.some(({ type }) => type === itemType);
const pizzaResolver = order => orderItemTypeResolver(order, ORDER_ITEM_TYPES.PIZZA);
const hamburgerResolver = order => orderItemTypeResolver(order, ORDER_ITEM_TYPES.ABC_BURGER);

const Icons = ({ order }) => (
  [
    ['takeaway', takeawayResolver],
    ['pizza', pizzaResolver],
    ['hamburger', hamburgerResolver],
  ].map(([type, resolver]) => (resolver(order)
    ? (
      <div key={`icon_type_${type}`} className={`order-list-order__column order-list-order__column_${type}`}>
        <div className={`icon-${type}`} />
      </div>
    )
    : (
      <div key={`icon_type_${type}`} className="order-list-order__column" />
    )))
);

const Timer = ({ time, deliveryWhenReady }) => {
  const [value, unit] = time.split(' ');
  return (
    <div className="order-list-order__column order-list-order__delivery-time order-list-order__delivery-time_timer">
      {deliveryWhenReady && (<div className="order-delivery-time__delivery-when-ready">{TIME_NOW}</div>)}
      <div className="order-delivery-time__minutes">{value}</div>
      <div className="order-delivery-time__unit">{unit}</div>
    </div>
  );
};
Timer.propTypes = {
  time: PropTypes.string.isRequired,
  deliveryWhenReady: PropTypes.bool.isRequired,
};
Timer.displayName = 'Timer';

export const OrderItem = ({ onOrderSelect, selectedOrder, order }) => {

  const showIcons = [ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPOSED].includes(order.status) === false;

  const tableNumberClassNames = classNames('order-list-order__column', 'order-list-order__column_table-number',
    {
      'order-list-order__column_table-number-not-given': Boolean(order.delivery.table) === false,
      'order-list-order__column_table-number-given': Boolean(order.delivery.table),
    });

  const orderItemCount = resolveOrderItems(order, true).length;
  return (
    <div
      data-test-key={`order-list__order-item-${order.displayName}`}
      onClick={() => onOrderSelect(order)}
      className={classNames('order-list__order', {
        'order-list__order_selected': selectedOrder.orderId === order.orderId,
        'order-list__order_urgency-0': order.urgencyLevel === undefined,
        'order-list__order_urgency-1': order.urgencyLevel === 1,
        'order-list__order_urgency-2': order.urgencyLevel === 2,
        'order-list__order_urgency-3': order.urgencyLevel === 3,
      })}
    >
      {order.timeSinceProductionFinished && (
        <div className="order-list-order__column order-list-order__column_tall">
          <div className={`order-item__production-finished-counter order-item__production-finished-counter_urgency-${order.timeSinceProductionFinished.urgencyLevel}`}>
            {order.timeSinceProductionFinished.time}
          </div>
          <div className="order-list-order__item-count">
            {orderItemCount}
          </div>
        </div>
      )}

      {!order.timeSinceProductionFinished && (
        <div className="order-list-order__column order-list-order__column_item-count">
          <div className="order-list-order__item-count">
            {orderItemCount}
          </div>
        </div>
      )}

      <div className="order-list-order__column_stack_start">
        {order.notes && order.notes.message && (
          <div className="order-list-order__column_stack_row">
            <div className="order-list-order__column order-list-order__column_message">
              <div className="icon-message" />
              <div className="order-list-order__column_message-text">
                Viesti
              </div>
            </div>
          </div>
        )}
        <div className="order-list-order__column_stack_row">
          <div className={tableNumberClassNames}>{(order.delivery.table || '?')}</div>
          {showIcons ? (
            <Icons order={order} />
          ) : (
            <div className="order-list-order__column order-list-order__column_order-id order-id-badge">
              {order.displayName}
            </div>
          )}
        </div>
      </div>

      {[ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPOSED].includes(order.status) === false && (
      <Timer
        time={timeInMinutesOrTime(order.timeLeftMinutes, order.delivery.time)}
        deliveryWhenReady={!!order.delivery.deliveryWhenReady}
      />
      )}

      {order.status === ORDER_STATUS_DELIVERED && (
      <Time time={order.delivery.deliveredTime} />
      )}

      {order.status === ORDER_STATUS_DISPOSED && (
      <Time time={order.delivery.disposedTime} />
      )}
    </div>
  );
};

OrderItem.propTypes = {
  onOrderSelect: PropTypes.func.isRequired,
  selectedOrder: PropTypes.shape({}), // TODO order shape
  order: PropTypes.shape({}).isRequired, // TODO order shape
};

OrderItem.defaultProps = {
  selectedOrder: {},
};

const OrderListHeading = (text, count) => (
  <div className="order-list-heading">
    <div className="text">
      {text}
    </div>
    <div className="count">
      {count}
    </div>
  </div>
);

const OrderGroupCreator = ({
  onOrderSelect, selectedOrder,
}) => {
  const OrderGroup = ({ status, orders }) => (
    <div data-test-key={`order-list__status-${status}`} className="orders-container" key={`order-group-${status}`}>
      {OrderListHeading(ORDER_STATUSES[status], orders.length)}
      {orders.map(order => (
        <OrderItem
          onOrderSelect={onOrderSelect}
          selectedOrder={selectedOrder}
          order={order}
          key={`order-${order.orderId}`}
        />
      ))}
    </div>
  );

  OrderGroup.propTypes = {
    status: PropTypes.string.isRequired,
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  return OrderGroup;
};

const OrderList = ({
  ordersByStatuses, onOrderSelect, selectedOrder, onOrderListScroll,
}) => (
  <div onScroll={onOrderListScroll} data-test-key="order-list" className="order-list">
    {ordersByStatuses.map(OrderGroupCreator({
      onOrderSelect, selectedOrder,
    }))}
  </div>
);

OrderList.propTypes = {
  ordersByStatuses: PropTypes.array.isRequired,
  onOrderSelect: PropTypes.func.isRequired,
  selectedOrder: PropTypes.object,
  onOrderListScroll: PropTypes.func.isRequired,
};

OrderList.defaultProps = {
  selectedOrder: undefined,
};

export default OrderList;
