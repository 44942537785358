import React from 'react';
import PropTypes from 'prop-types';
import BlacklistItemCategoryHeader from './blacklistItemCategoryHeader';
import BlacklistItemRow from './blacklistItemRow';

const BlacklistContainer = ({ categories, togglePortionAvailability }) => (
  (
    <React.Fragment>
      {categories.map(category => (
        <React.Fragment key={category.name}>
          <BlacklistItemCategoryHeader title={category.name} />
          <div>
            {category.portions.map(portion => <BlacklistItemRow key={portion.ean} portion={portion} togglePortionAvailability={togglePortionAvailability} />)}
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  )
);

BlacklistContainer.propTypes = {
  categories: PropTypes.array.isRequired,
  togglePortionAvailability: PropTypes.func.isRequired,
};
export default BlacklistContainer;
