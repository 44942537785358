import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  groupOrderItems,
  resolveOrderItems,
  resolveOrderItemsSorted,
  I18n,
} from '../../util';
import {
  ORDER_STATUS_ACTION_BUTTON,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_ACTIONS,
  ORDER_STATUSES,
  ORDER_STATUS_DISPOSED,
  VIEW_ORDER_SETTINGS,
  ORDER_STATUS_PRODUCTION_STARTED,
  ORDER_STATUS_AUTHORIZATION_FINISHED,
  ORDER_CUSTOMIZATIONS,
  TIME_NOW,
} from '../../constants';

import './details.css';

const EndState = ({ order }) => {
  let buttonText = '';
  switch (order.status) {
    case ORDER_STATUS_DELIVERED:
      buttonText = `Tarjoilu aloitettu ${order.delivery.deliveredTime
        ? I18n.time.format(moment(order.delivery.deliveredTime))
        : ''}`;
      break;
    case ORDER_STATUS_DISPOSED:
      buttonText = `Ei voitu toimittaa ${order.delivery.disposedTime
        ? I18n.time.format(moment(order.delivery.disposedTime))
        : ''}`;
      break;
    default:
      break;
  }

  const buttonClassNames = classNames(
    'order-details__action',
    'order-details__action_disabled',
  );

  const totalPrice = order.items.reduce((sum, { price }) => sum + price, 0);

  return (
    <div
      data-test-key="order-details__move-to-next-status"
      className={buttonClassNames}
    >
      <div className="order-details-action__left">
        &nbsp;
      </div>
      <div className="order-details-action__middle">
        {buttonText}
      </div>
      <div className="order-details-action__right">
        {I18n.currency.format(totalPrice / 100)}
      </div>
    </div>
  );
};
EndState.propTypes = {
  order: PropTypes.object.isRequired,
};

const ActionButton = ({
  order,
  orderAction,
  pending,
  nextStatus,
}) => {
  const disabled = pending === true;

  const buttonClassNames = classNames(
    'order-details__action',
    `order-details__action_${nextStatus}`,
    {
      'order-details__action_disabled': disabled,
    },
  );
  const buttonText = ORDER_STATUS_ACTION_BUTTON[nextStatus];

  return (
    <div
      data-test-key={`order-details__move-to-status_${nextStatus}`}
      className={buttonClassNames}
      onClick={() => !disabled && orderAction(order)}
    >
      {buttonText}
    </div>
  );
};

ActionButton.propTypes = {
  order: PropTypes.object.isRequired,
  orderAction: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  nextStatus: PropTypes.oneOf(Object.keys(ORDER_STATUSES)).isRequired,
};

const AdditionalProduct = product => product.receiptName || product.name;

const CustomizationChoice = ({
  choice, id, name, receiptName,
}) => (
  choice === undefined
    ? receiptName || name || id
    : receiptName || name || choice
);

const AdditionalProductsAndCustomization = (item) => {
  const additionalProducts = item.additionalProducts ? item.additionalProducts.map(AdditionalProduct) : [];
  const addedCustomizationChoices = item.customizationChoices ? item.customizationChoices.filter(c => c.type !== 'remove').map(CustomizationChoice) : [];
  const removedCustomizationChoices = item.customizationChoices ? item.customizationChoices.filter(c => c.type === 'remove').map(CustomizationChoice) : [];

  if (additionalProducts.length === 0 && addedCustomizationChoices.length === 0 && removedCustomizationChoices.length === 0) {
    return null;
  }

  return (
    <div>
      {additionalProducts.length > 0 && (
        <div className="order-details-order-item__additional-products-and-customization">
          {additionalProducts.join(', ')}
        </div>
      )}
      {addedCustomizationChoices.length > 0 && (
        <div className="order-details-order-item__additional-products-and-customization">
          {addedCustomizationChoices.join(', ')}
        </div>
      )}
      {removedCustomizationChoices.length > 0 && (
        <div className="order-details-order-item__removed-additional-products-and-customization">
          {removedCustomizationChoices.join(', ')}
        </div>
      )}
    </div>
  );
};

const OrderItemGroup = ({ itemCount, orderItem, showOrderItemPrice }) => (
  <div data-test-key={`order-items__order-item-${orderItem.ean}`} className="order-details-order-item">
    <div className="order-details-order-item__item-count">{itemCount}</div>
    <div className="order-details-order-item__middle">
      <div className="order-details-order-item__name">{orderItem.receiptName || orderItem.name}</div>
      {(orderItem.additionalProducts || orderItem.customizationChoices)
&& AdditionalProductsAndCustomization(orderItem)}
    </div>
    <div className="order-details-order-item__right">
      {orderItem.type === 'PIZZA' && (
      <div className="order-details-order-item__item_pizza">
        <div className="icon-pizza" />
      </div>
      )}
      {orderItem.type === 'ABC_BURGER' && (
      <div className="order-details-order-item__item_hamburger">
        <div className="icon-hamburger" />
      </div>
      )}
      {showOrderItemPrice && (
      <div className="order-details-order-item__price">
        {I18n.currency.format((itemCount * orderItem.price) / 100)}
      </div>
      )}
    </div>
  </div>
);

OrderItemGroup.propTypes = {
  itemCount: PropTypes.number.isRequired,
  orderItem: PropTypes.object.isRequired,
  showOrderItemPrice: PropTypes.bool.isRequired,
};

const OrderDetails = ({
  order, moveToStatus,
  pendingActions,
  toggleOptions,
}) => {
  const relevantOrderItemCount = resolveOrderItems(order, true).length;
  const relevantOrderItems = resolveOrderItemsSorted(order);
  const showOrderItemPrice = [ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPOSED].includes(order.status);

  const newCustomizationFormat = order.notes && order.notes.orderCustomizations
    ? Object.entries(order.notes.orderCustomizations)
      .filter(([, customization]) => customization && customization.enabled && (customization.receiptName || customization.name))
      .map(([, customization]) => customization.receiptName || customization.name)
      .sort()
    : [];
  // legacy customizations
  const oldCustomizations = (newCustomizationFormat.length === 0 && order.notes && order.notes.customizations
    && Object.entries(order.notes.customizations)
      .map(([key, val]) => (val ? key : undefined))
      .filter(Boolean)
      .sort((a, b) => Object.keys(ORDER_CUSTOMIZATIONS).indexOf(a) - Object.keys(ORDER_CUSTOMIZATIONS).indexOf(b))
      .map(id => ORDER_CUSTOMIZATIONS[id]))
    || [];

    const orderCustomizationNames = [...oldCustomizations, ...newCustomizationFormat];
    const customizationHeader = newCustomizationFormat && newCustomizationFormat.length > 0 ? 'TILAUKSEN LISÄVALINNAT' : 'PAKATAAN MUKAAN';
  return (
    <div
      data-test-key={`order-details__order-${order.displayName}`}
      className={classNames('order-details', {
        'order-details_urgency-0': order.urgencyLevel === undefined,
        'order-details_urgency-1': order.urgencyLevel === 1,
        'order-details_urgency-2': order.urgencyLevel === 2,
        'order-details_urgency-3': order.urgencyLevel === 3,
      })}
    >
      <div data-test-key="order-details__header" className="order-details__header">
        <div className="order-details-header__left">
          <div className={classNames('order-details-header__item-count', {
            'order-details-header__item-count_urgency-0': order.urgencyLevel === undefined,
            'order-details-header__item-count_urgency-1': order.urgencyLevel === 1,
            'order-details-header__item-count_urgency-2': order.urgencyLevel === 2,
            'order-details-header__item-count_urgency-3': order.urgencyLevel === 3,
          })}
          >
            {relevantOrderItemCount}
          </div>
          {order.delivery.takeaway && (
            <div className="order-details-header__takeaway">Take away</div>
          )}
        </div>
        <div className="order-details-header__right">
          <div className="order-id-badge details-order-id-badge">{order.displayName}</div>
          <div className={classNames('order-details-header__time', {
            'order-details-header__time_urgency-0': order.urgencyLevel === undefined,
            'order-details-header__time_urgency-1': order.urgencyLevel === 1,
            'order-details-header__time_urgency-2': order.urgencyLevel === 2,
            'order-details-header__time_urgency-3': order.urgencyLevel === 3,
          })}
          >
            {I18n.time.format(moment(order.delivery.time))} {order.delivery.deliveryWhenReady && `(${TIME_NOW})`}
          </div>
          {(order.status === ORDER_STATUS_AUTHORIZATION_FINISHED || order.status === ORDER_STATUS_PRODUCTION_STARTED) && (
            <div
              onClick={() => toggleOptions(VIEW_ORDER_SETTINGS)}
              className="order-settings-button"
            >
              <div className="icon-settings" />
            </div>
          )}
        </div>
      </div>
      <div data-test-key="order-details__order-items" className="order-details__order-items">
        {order.notes && order.notes.message && (
          <div data-test-key="order-items__order-item-message" className="order-details-order-message">
            <div className="order-details-order-message__icon"><div className="icon-message" /></div>
            <div className="order-details-order-message__middle">
              <div className="order-details-order-message__message">{order.notes.message}</div>
            </div>
          </div>
        )}
        {groupOrderItems(relevantOrderItems).map((orderItemGroup, i) => (
          <OrderItemGroup {...orderItemGroup} showOrderItemPrice={showOrderItemPrice} key={`order-item-group-${i}`} />
        ))}
        {orderCustomizationNames && orderCustomizationNames.length > 0 && (
          <>
            <div className="order-details-order-customizations">
              <div className="order-details-order-customizations__customizations-customization-text">
                {customizationHeader}
              </div>
              <br />
              <div className="order-details-order-customizations__customizations">
                {orderCustomizationNames.map(customizationName => (
                  <div key={customizationName} className="order-details-order-customizations__customizations-customization">
                    <div className="order-details-order-customizations__customizations-customization-text customizations-customization-text--centered">
                      {customizationName}
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </>
        )}
      </div>
      <div className="order-details__actions">
        {[ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPOSED].includes(order.status) && (
          <EndState order={order} />
        )}
        {ORDER_STATUS_ACTIONS[order.status].map(nextStatus => (
          <ActionButton
            key={`${order.displayName}-${nextStatus}`}
            order={order}
            orderAction={() => moveToStatus(order, nextStatus)}
            pending={pendingActions.some(({ action }) => (
              action.action === 'updateStatus' && action.order.orderId === order.orderId
            ))}
            nextStatus={nextStatus}
          />
        ))}
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  moveToStatus: PropTypes.func.isRequired,
  pendingActions: PropTypes.array.isRequired,
  toggleOptions: PropTypes.func.isRequired,
};

export default OrderDetails;
