import React from 'react';
import PropTypes from 'prop-types';

import './fullScreenNotification.css';

import {
  URGENCY_LEVELS,
} from '../constants';
import FullScreenNotificationComponent from './fullScreenNotificationComponent';

const FullScreenNotification = ({
  urgency,
  marketOrderUrgency,
  title,
  descriptions,
  closeButtonText,
  buffetOrderAmount,
  splitView,
  marketOrderTime,
  tableNumberGiven,
}) => {
  const tableNumberGivenDescription = tableNumberGiven ? 'Pöytänumero annettu' : '';
  return (splitView ? (
    <div className="full-screen-outer-container">
      <FullScreenNotificationComponent
        title={marketOrderTime}
        urgency={marketOrderUrgency}
        closeButtonText={closeButtonText}
        smallerFonts
        descriptions={['Keräily']}
      />
      <FullScreenNotificationComponent
        title={title}
        urgency={urgency}
        smallerFonts
        closeButtonText={closeButtonText}
        descriptions={[...(buffetOrderAmount > 0
          ? [`${buffetOrderAmount} x noutopöytä`, ...descriptions, tableNumberGivenDescription]
          : [...descriptions, tableNumberGivenDescription])]}
      />
    </div>
  ) : (
    <FullScreenNotificationComponent
      title={title}
      urgency={urgency}
      closeButtonText={closeButtonText}
      descriptions={[...(buffetOrderAmount > 0
        ? [`${buffetOrderAmount} x noutopöytä`, ...descriptions, tableNumberGivenDescription]
        : [...descriptions, tableNumberGivenDescription])]}
    />
  ));
};
FullScreenNotification.propTypes = {
  urgency: PropTypes.oneOf(URGENCY_LEVELS),
  marketOrderUrgency: PropTypes.oneOf(URGENCY_LEVELS),
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.array,
  closeButtonText: PropTypes.string.isRequired,
  buffetOrderAmount: PropTypes.number.isRequired,
  tableNumberGiven: PropTypes.bool.isRequired,
  splitView: PropTypes.bool,
  marketOrderTime: PropTypes.string,
};

FullScreenNotification.defaultProps = {
  urgency: undefined,
  marketOrderUrgency: undefined,
  descriptions: [],
  splitView: false,
  marketOrderTime: undefined,
};

export default FullScreenNotification;
