import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { I18n } from '../util';

const Time = ({ time }) => (
  <div className="order-list-order__column order-list-order__delivery-time order-list-order__delivery-time_time">
    {time ? I18n.time.format(moment(time)) : ''}
  </div>
);
Time.propTypes = {
  time: PropTypes.string.isRequired,
};

export default Time;
