export const ORDER_STATUS_AUTHORIZATION_FINISHED = 'O_AUTHORIZATION_FINISHED';
export const ORDER_STATUS_PRODUCTION_STARTED = 'O_PRODUCTION_STARTED';
export const ORDER_STATUS_PRODUCTION_FINISHED = 'O_PRODUCTION_FINISHED';
export const ORDER_STATUS_DELIVERED = 'E_DELIVERED';
export const ORDER_STATUS_EXPIRED = 'E_EXPIRED';
export const ORDER_STATUS_DISPOSED = 'E_DISPOSED';

export const ORDER_STATUSES = {
  [ORDER_STATUS_AUTHORIZATION_FINISHED]: 'Jonossa',
  [ORDER_STATUS_PRODUCTION_STARTED]: 'Valmistuksessa',
  [ORDER_STATUS_PRODUCTION_FINISHED]: 'Valmiina',
  [ORDER_STATUS_DELIVERED]: 'Toimitettu',
  [ORDER_STATUS_EXPIRED]: 'Vanhentunut',
  [ORDER_STATUS_DISPOSED]: 'Ei voitu toimittaa',
};

export const CONFIRM_TITLE_TABLE_NUMBER_MISSING = 'Pöytänumero puuttuu. Haluatko silti aloittaa tarjoilun?';
export const CONFIRM_TITLE_DO_YOU_WANT_TO_START = 'Toivottuun valmistumisaikaan on _TIME_. Haluatko varmasti aloittaa valmistuksen?';
export const CONFIRM_TITLE_X_MINUTES_TO_DESIRED_DELIVERY_TIME = 'Toivottuun valmistumisaikaan on _TIME_ ja pöytänumero puuttuu. Haluatko varmasti siirtää tilauksen salille tarjoiltavaksi?';

export const TIME_NOW = 'Heti';

export const ORDER_STATUS_ACTIONS = {
  [ORDER_STATUS_AUTHORIZATION_FINISHED]: [ORDER_STATUS_PRODUCTION_STARTED],
  [ORDER_STATUS_PRODUCTION_STARTED]: [ORDER_STATUS_PRODUCTION_FINISHED],
  [ORDER_STATUS_PRODUCTION_FINISHED]: [ORDER_STATUS_DELIVERED],
  [ORDER_STATUS_DELIVERED]: [],
  [ORDER_STATUS_EXPIRED]: [ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPOSED],
  [ORDER_STATUS_DISPOSED]: [],
};

export const VIEW_KITCHEN = 'KITCHEN';
export const VIEW_FRONT_OF_HOUSE = 'FRONT_OF_HOUSE';
export const VIEW_KITCHEN_AND_FRONT_OF_HOUSE = 'KITCHEN_AND_FRONT_OF_HOUSE';
export const VIEW_CASHIER = 'CASHIER';
export const VIEW_CASHIER_REPORT = 'CASHIER_REPORT';
export const VIEW_CHANGE_PIN = 'CHANGE_PIN';
export const VIEW_DELIVERY_HISTORY = 'DELIVERY_HISTORY';

export const VIEW_OPTIONS_VIEWS = 'OPTIONS_VIEWS';
export const VIEW_OPTIONS_QUEUE = 'OPTIONS_QUEUE';
export const VIEW_ORDER_SETTINGS = 'ORDER_SETTINGS';
export const VIEW_BUFFETS = 'ORDER_BUFFETS';
export const VIEW_CONFIRM = 'VIEW_CONFIRM';
export const VIEW_MANAGE_PORTIONS = 'MANAGE_PORTIONS';
export const VIEW_MARKET_ORDERS = 'VIEW_MARKET_ORDERS';

export const ORDER_ITEM_TYPES = {
  PIZZA: 'PIZZA',
  ABC_BURGER: 'ABC_BURGER',
  DRINK: 'DRINK',
  OTHER: 'OTHER',
  EXTRA_DRINK: 'EXTRA_DRINK',
};

export const VIEWS = {
  [VIEW_KITCHEN]: {
    title: 'Keittiö',
    orderStatuses: [
      ORDER_STATUS_PRODUCTION_STARTED,
      ORDER_STATUS_AUTHORIZATION_FINISHED,
    ],
  },
  [VIEW_FRONT_OF_HOUSE]: {
    title: 'Sali',
    orderStatuses: [
      ORDER_STATUS_EXPIRED,
      ORDER_STATUS_PRODUCTION_FINISHED,
    ],
    VIEWS: {
      [VIEW_CASHIER_REPORT]: {
        title: 'Näytä kassaraportit',
      },
      [VIEW_MANAGE_PORTIONS]: {
        title: 'Myytävät annokset',
      },
      [VIEW_CHANGE_PIN]: {
        title: 'Vaihda tunnusluku',
      },
    },
  },
  [VIEW_KITCHEN_AND_FRONT_OF_HOUSE]: {
    title: 'Keittiö/Sali',
    orderStatuses: [
      ORDER_STATUS_EXPIRED,
      ORDER_STATUS_PRODUCTION_FINISHED,
      ORDER_STATUS_PRODUCTION_STARTED,
      ORDER_STATUS_AUTHORIZATION_FINISHED,
    ],
  },
  [VIEW_DELIVERY_HISTORY]: {
    title: 'Toimitushistoria',
    orderStatuses: [
      ORDER_STATUS_DISPOSED,
      ORDER_STATUS_DELIVERED,
    ],
  },
};

export const ORDER_STATUSES_SECONDARY_ITEM_TYPES_HIDDEN = [
  // SK-15863 Show secondary items always, at least for now
  // ORDER_STATUS_AUTHORIZATION_FINISHED,
  // ORDER_STATUS_PRODUCTION_STARTED,
];

export const SECONDARY_ITEM_TYPES = [
  ORDER_ITEM_TYPES.DRINK,
  ORDER_ITEM_TYPES.EXTRA_DRINK,
  ORDER_ITEM_TYPES.OTHER,
];

export const ORDER_STATUS_ACTION_BUTTON = {
  [ORDER_STATUS_PRODUCTION_STARTED]: 'Aloita valmistus',
  [ORDER_STATUS_PRODUCTION_FINISHED]: 'Siirrä tilaus salille',
  [ORDER_STATUS_DELIVERED]: 'Aloita tarjoilu',
  [ORDER_STATUS_DISPOSED]: 'Ei voitu toimittaa',
};

export const ORDER_CUSTOMIZATIONS = {
  ORDER_CUSTOMIZATION_CUTLERY: 'Aterimet',
  ORDER_CUSTOMIZATION_KETCHUP: 'Ketsuppi',
  ORDER_CUSTOMIZATION_SALT: 'Suola',
  ORDER_CUSTOMIZATION_PEPPER: 'Pippuri',
}

export const QUEUE_OPTIONS_MINUTES = [15, 20, 30, 45, 60, 120];

export const MONTHS = {
  '01': 'tammikuu',
  '02': 'helmikuu',
  '03': 'maaliskuu',
  '04': 'huhtikuu',
  '05': 'toukokuu',
  '06': 'kesäkuu',
  '07': 'heinäkuu',
  '08': 'elokuu',
  '09': 'syyskuu',
  10: 'lokakuu',
  11: 'marraskuu',
  12: 'joulukuu',
};

export const PRICE_TYPE_REGULAR = 'REGULAR_PRICE';
export const PRICE_TYPE_EMPLOYEE_DISCOUNT = 'EMPLOYEE_DISCOUNT';
export const PRICE_TYPE_CAMPAIGN_DISCOUNT = 'CAMPAIGN_DISCOUNT';
export const PRICE_TYPE_S_BUSINESS = 'S_BUSINESS_DISCOUNT';
export const PRICE_TYPES = [PRICE_TYPE_REGULAR, PRICE_TYPE_EMPLOYEE_DISCOUNT,
  PRICE_TYPE_CAMPAIGN_DISCOUNT, PRICE_TYPE_S_BUSINESS];
export const PRICE_TYPE_TEXTS = {
  [PRICE_TYPE_REGULAR]: 'Normaalihintaiset',
  [PRICE_TYPE_EMPLOYEE_DISCOUNT]: 'Henkilökuntahintaiset',
  [PRICE_TYPE_CAMPAIGN_DISCOUNT]: 'Kampanjahintaiset',
  [PRICE_TYPE_S_BUSINESS]: 'S-Business',
};

export const URGENCY_LEVEL_NONE = 1;
export const URGENCY_LEVEL_LOW = 2;
export const URGENCY_LEVEL_HIGH = 3;
export const URGENCY_LEVELS = [
  URGENCY_LEVEL_NONE,
  URGENCY_LEVEL_LOW,
  URGENCY_LEVEL_HIGH,
];

// time in minutes until market order becomes high urgency
export const ORDER_DEADLINE_HIGH_URGENCY_THRESHOLD = 30;
// time in minutes until market orders becomes low urgency
export const ORDER_DEADLINE_LOW_URGENCY_THRESHOLD = 60;
