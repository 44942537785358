import React from 'react';
import PropTypes from 'prop-types';

import './confirm.css';

const Confirm = ({ onClick, onCancel, title }) => (
  <div
    data-test-key="full-screen-confirm"
    className="full-screen-confirm"
  >
    <div className="full-screen-confirm__title">{title}</div>
    <div className="full-screen-confirm__buttons">
      <div className="button button-warn" onClick={onCancel}>
        En
      </div>
      <div className="button button-positive" onClick={onClick}>
        Kyllä
      </div>
    </div>
  </div>
);

Confirm.propTypes = {
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Confirm;
