import moment from 'moment-timezone';
import isequal from 'lodash.isequal';
import sortBy from 'lodash.sortby';

import {
  ORDER_STATUS_AUTHORIZATION_FINISHED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUSES_SECONDARY_ITEM_TYPES_HIDDEN,
  SECONDARY_ITEM_TYPES,
  ORDER_STATUS_DISPOSED,
  ORDER_STATUS_PRODUCTION_FINISHED,
  ORDER_STATUS_EXPIRED,
  VIEWS,
  VIEW_FRONT_OF_HOUSE,
  VIEW_MANAGE_PORTIONS,
  URGENCY_LEVEL_LOW,
  URGENCY_LEVEL_NONE,
  URGENCY_LEVEL_HIGH,
  ORDER_DEADLINE_HIGH_URGENCY_THRESHOLD,
  ORDER_DEADLINE_LOW_URGENCY_THRESHOLD,

} from './constants';

const leftPadZero = number => (Math.abs(number) < 10 ? `0${number}` : `${number}`);

/**
 * Resolves order delivery time left, urgency level and time since production finished
 *
 * @param {{delivery: {time: string}}} order Order object with delivery time
 * @param {number} currentTime Current time as Unix time with milliseconds
 * @returns {{timeLeftMinutes: number, urgencyLevel: number, timeSinceProductionFinished: string}} Time left in minutes and
 * urgency level from 0 (not urgen) to 2 (urgent)
 */
export const resolveUrgencyLevel = (order, currentTime) => {
  if ([ORDER_STATUS_DELIVERED, ORDER_STATUS_DISPOSED].includes(order.status)) {
    return {};
  }
  const timeLeftMinutes = Math.floor(
    moment.duration(moment(order.delivery.time).diff(moment(currentTime))).as('minutes'),
  );

  let timeSinceProductionFinished;
  if ([ORDER_STATUS_PRODUCTION_FINISHED, ORDER_STATUS_EXPIRED].includes(order.status)) {
    const secondsSinceProductionFinished = Math.abs(
      Math.floor(
        moment.duration(moment(order.delivery.productionFinishedTime).diff(moment(currentTime))).as('seconds'),
      ),
    );
    const seconds = secondsSinceProductionFinished % 60;
    const minutes = (secondsSinceProductionFinished - seconds) / 60;
    timeSinceProductionFinished = {
      time: `${leftPadZero(minutes)}:${leftPadZero(seconds)}`,
      urgencyLevel: minutes < 15 ? 1 : 3,
    };
  }

  let urgencyLevel;
  if (timeLeftMinutes <= 0) { // 0 minutes left or late
    urgencyLevel = 3;
  } else if (timeLeftMinutes <= 10) { // less than 10 minutes time
    urgencyLevel = 2;
  } else {
    urgencyLevel = 1;
  }

  return timeSinceProductionFinished
    ? { timeLeftMinutes, urgencyLevel, timeSinceProductionFinished }
    : { timeLeftMinutes, urgencyLevel };
};

/**
 * Filter and group given orders to given status groups
 *
 * @param {Array<Object>} orders
 * @param {Array<string>} statuses
 * @returns {Array<{status: string, orders: Array<Object>}>} Array of objects containing status and corresponding orders
 */
export const filterAndGroupOrders = (orders, statuses) => orders.reduce((acc, order) => {
  for (const statusOrders of acc) {
    if (order.status === statusOrders.status) {
      statusOrders.orders.push(order);
      return acc;
    }
  }
  return acc;
}, statuses.map(status => ({ status, orders: [] })));

/**
 * Returns a text representation of queue length
 *
 * @param {number} queueMinutes
 * @returns {string} Queue length in minutes if queue lenfth <60 "15 min" or hours if >=60 "1 h"
 */
export const queueText = queueMinutes => (queueMinutes >= 60 ? `${queueMinutes / 60} h` : `${queueMinutes} min`);

/**
 * Returns the appropriate items of the order relevant to the order's status
 *
 * @param {Object} order
 * @returns {number} Relevant items in order
 */
export const resolveOrderItems = (order, alwaysHideSecondaryItems = false) => order.items.filter(
  item => !item.type
  || !((ORDER_STATUSES_SECONDARY_ITEM_TYPES_HIDDEN.includes(order.status) || alwaysHideSecondaryItems)
        && SECONDARY_ITEM_TYPES.includes(item.type)),
);

export const resolveOrderItemsSorted = order => sortBy(
  resolveOrderItems(order), [item => SECONDARY_ITEM_TYPES.includes(item.type)],
);

const timeLeftAscComparator = (a, b) => (a.timeLeftMinutes < b.timeLeftMinutes ? -1 : 1);
/**
 * Takes orders grouped by statuses and returns orders grouped by urgency levels
 *
 * @param {Array} ordersByStatuses Orders by statuses, containing urgency levels
 * @returns {Object} Urgency levels as keys, arrays of orders as values
 */
export const groupOrdersByUrgencyLevel = ordersByStatuses => (
  ordersByStatuses.reduce((acc, ordersByStatus) => ordersByStatus.orders.reduce((acc2, order) => ({
    ...acc2,
    [order.urgencyLevel]: acc2[order.urgencyLevel]
      ? [...acc2[order.urgencyLevel], order].sort(timeLeftAscComparator)
      : [order],
  }), acc), {})
);

export const orderWithUrgencyData = (order, time) => (order
  ? {
    ...order,
    ...resolveUrgencyLevel(order, time),
  }
  : null
);

/**
 * Takes orders grouped by statuses and current time, calculates urgency and time left to delivery.
 * Also returns orders grouped by urgency level.
 *
 * @param {Array} ordersByStatuses
 * @param {number} time Time as Unix time with milliseconds
 * @return {Object} Returns orders by statuses (with urgency, time left added) and orders grouped by urgency level
 */
export const withUrgencyLevelAndTimeLeft = (ordersByStatuses, time) => {
  const ordersByStatusesWithUrgencyLevelAndTimeLeft = ordersByStatuses.map(ordersByStatus => ({
    ...ordersByStatus,
    orders: ordersByStatus.orders.map(order => orderWithUrgencyData(order, time)),
  }));
  return {
    ordersWithUrgencyByStatuses: ordersByStatusesWithUrgencyLevelAndTimeLeft,
    ordersByUrgencyLevel: groupOrdersByUrgencyLevel(ordersByStatusesWithUrgencyLevelAndTimeLeft),
  };
};

/**
 * Returns first order of first status group
 * @param {array} ordersByStatuses Status groups containing orders
 * @returns {Object|undefined} Returns order object or undefined if no orders at all
 */
export const getFirstOrderOfFirstStatus = (ordersByStatuses) => {
  const [firstOrderOfFirstStatus] = ordersByStatuses.reduce((acc, { orders }) => [
    ...acc,
    ...orders,
  ], []);
  return firstOrderOfFirstStatus;
};

class TimeWithFormatting {
  constructor() {
    this.dateTimeFormat = new Intl.DateTimeFormat('fi-FI', {
      timeZone: 'Europe/Helsinki',
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  format(date) {
    const parts = this.dateTimeFormat.formatToParts(date)
      .reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {});
    return `${parts.hour}:${parts.minute}`;
  }
}

class DateTimeWithFormatting {
  constructor() {
    this.dateTimeFormat = new Intl.DateTimeFormat('fi-FI', {
      timeZone: 'Europe/Helsinki',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  format(date) {
    const parts = this.dateTimeFormat.formatToParts(date)
      .reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {});
    return `${parts.day}.${parts.month}.${parts.year} klo ${parts.hour}:${parts.minute}`;
  }
}

const timeWithFormatting = new TimeWithFormatting();

export const I18n = {
  currency: new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }),
  date: new Intl.DateTimeFormat('fi-FI', { timeZone: 'Europe/Helsinki' }),
  time: timeWithFormatting,
  dateTime: new DateTimeWithFormatting(),
};


export const timeInMinutesOrTime = (timeLeftMinutes, deliveryTime) => (Math.abs(timeLeftMinutes) >= 60
  ? timeWithFormatting.format(moment(deliveryTime))
  : `${Math.floor(timeLeftMinutes)} min`
);

const urgencyLevelComparator = (a, b) => (a.urgencyLevel < b.urgencyLevel ? 1 : -1);
/**
 * Get orders that have changed (risen) urgency level and return them in desceding order (urgency level)
 * @param {array} previousOrders Previous orders
 * @param {*} currentOrders Current orders
 * @return {array} Array of orders having changed urgency level, sorted by urgency level descending
 */
export const getUrgencyLevelChangedOrders = (previousOrders, currentOrders) => currentOrders.filter(co => (
  previousOrders.find(po => co.orderId === po.orderId && co.urgencyLevel > po.urgencyLevel)))
  .sort(urgencyLevelComparator);

export const getInitialView = () => window.localStorage.getItem('initial-view');
export const setInitialView = view => window.localStorage.setItem('initial-view', view);

export const groupOrderItems = orderItems => orderItems.reduce((acc, orderItem) => {
  const existing = acc.find(groupedOrderItem => isequal(orderItem, groupedOrderItem.orderItem));

  if (existing) {
    existing.itemCount += 1;
    return acc;
  }
  acc.push({ itemCount: 1, orderItem });
  return acc;
}, []);
export const deliveredOrdersFilter = order => order.status === ORDER_STATUS_DELIVERED;
export const getTotalPortionCount = menu => (menu && menu.portionGroups ? menu.portionGroups.reduce((acc, pg) => pg.portions.length + acc, 0) : 0);
export const getBlacklistedPortionCount = menu => (menu && menu.portionGroups ? menu.portionGroups
  .reduce((acc, pg) => pg.portions
    .filter(portion => !portion.available).length + acc, 0) : 0);
export const getAvailablePortionCount = menu => (menu && menu.portionGroups ? menu.portionGroups
  .reduce((acc, pg) => pg.portions
    .filter(portion => portion.available).length + acc, 0) : 0);

export const getBlacklistHeaderText = menu => (menu ? `${VIEWS[VIEW_FRONT_OF_HOUSE].VIEWS[VIEW_MANAGE_PORTIONS].title} (${getAvailablePortionCount(menu)}/${getTotalPortionCount(menu)})` : `${VIEWS[VIEW_FRONT_OF_HOUSE].VIEWS[VIEW_MANAGE_PORTIONS].title} (...)`);

export const orderDeadlineToMoment = (deliveryTime, now = moment()) => {
  const startOfDelivery = deliveryTime.split('-')[0];
  const formatted = now.format('YYYY-MM-DD');
  const newMoment = moment(`${formatted} ${startOfDelivery}`);
  return newMoment;
};
export const getMinutesToDeadline = (order, now = moment()) => orderDeadlineToMoment(order.deliveryTime, now).diff(now, 'minute');

export const marketOrderIsUrgent = (order, now = moment()) => getMinutesToDeadline(order, now) <= ORDER_DEADLINE_HIGH_URGENCY_THRESHOLD;

export const getOrderUrgencyLevel = (order, now = moment()) => {
  let urgencyLevel;
  if (marketOrderIsUrgent(order, now)) {
    urgencyLevel = URGENCY_LEVEL_HIGH;
  } else if (getMinutesToDeadline(order, now) <= ORDER_DEADLINE_LOW_URGENCY_THRESHOLD) {
    urgencyLevel = URGENCY_LEVEL_LOW;
  } else urgencyLevel = URGENCY_LEVEL_NONE;
  return urgencyLevel;
};

export const orderWithUrgencyTimeData = (order, localTime) => {
  if (!order || !order.deliveryTime) return order;
  return ({
    ...order,
    urgencyLevel: getOrderUrgencyLevel(order, localTime),
    remainingMinutes: getMinutesToDeadline(order, localTime),
    deadline: orderDeadlineToMoment(order.deliveryTime, localTime).utc(),
  });
};

export const deliveryEndComparator = (a, b) => {
  if (!a || !b || !a.deliveryTime || !b.deliveryTime
    || !a.deliveryTime.includes('-') || !b.deliveryTime.includes('-')) return 0;

  const endMomentA = moment(parseInt(a.deliveryTime.split('-')[1], 10), 'HH');
  const endMomentB = moment(parseInt(b.deliveryTime.split('-')[1], 10), 'HH');

  if (endMomentA.isBefore(endMomentB)) return -1;
  if (endMomentB.isBefore(endMomentA)) return 1;
  return 0;
};

const isOrderDeliveryWhenReady = order => order && order.delivery && order.delivery.deliveryWhenReady;

const isOrderInInitialState = order => order && order.status && order.status === ORDER_STATUS_AUTHORIZATION_FINISHED;

export const hasDeliveryWhenReadyInQueue = orders => orders && orders.some(order => isOrderDeliveryWhenReady(order) && isOrderInInitialState(order));
