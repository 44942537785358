import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { runWithAdal } from 'react-adal';
import moment from 'moment-timezone';
import { authContext } from './auth/adal-config';

import appLock from './app-lock';

import { getInitialView } from './util';

import App from './appIndex';

moment.tz.setDefault('Europe/Helsinki');

// hard coded pin for the time being
if (appLock.hasPin() === false) appLock.setPin('4444');

const DO_NOT_LOGIN = false;
runWithAdal(authContext, () => {
  ReactDOM.render(
    <App
      initialTime={moment()}
      locked={appLock.isLocked()}
      initialView={getInitialView()}
    />,
    document.getElementById('root'),
  );
}, DO_NOT_LOGIN);

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}
